.snippets-bar {
    margin: 20px 0;
}

.snippets-bar .attribute, .snippets-text:not(.disabled) .attribute {
    cursor: move;
}

.snippets-bar .attribute {
    margin: 5px;
}

.snippets-bar .attribute:after, .snippets-text .attribute:after {
    background-color: #12b2d9;
}

[placeholder]:empty::before {
    content: attr(placeholder);
    color: #555;
}

[placeholder]:empty:focus::before {
    content: "";
}