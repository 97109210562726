.builder-schedule-recurrence {
    .custom-recurrence {
        .builder--style-audience {
            margin-top: 24px;

            .builder__audience-tags {
                .tags__item {
                    font-size: 14px;
                }
            }
        }
    }
}