.landing-page-main-component {
    overflow: auto;
    height: 100%;
}

.landing-page-main-component .message__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
    position: relative;
    overflow: visible;
}

.landing-page-main-component .message__element .video-link-buttons {
    position: absolute;
    bottom: -31px;
}

.landing-page-main-component .message__element.video-element {
    position: relative;
}

.landing-page-main-component .message__element .video-link-buttons span {
    display: inline-block;
    margin-right: 10px;
    color: #12b2d9;
    cursor: pointer;
}

.preview--type-phone .landing-page-main-component .message__element .message__text {
    max-width: 300px;
}

.landing-page-main-component .message__element textarea {
    border: none;
    width: 100%;
    text-align: center;
}