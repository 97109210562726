.container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #F1FAFE;
}
.container .content {
    position: absolute;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container .content .image {
    width: 500px;
    height: 500px;
    background-position: center;
}