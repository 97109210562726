.not-supported-browser-notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffc75f;
    line-height: 4rem;
    z-index: 100;
    text-align: center;
}

@media (min-width: 375px) {
    body.not-supported-browser .header.header--push {
        height: 155px;
    }

    body.not-supported-browser .header,
    body.not-supported-browser .settings,
    body.not-supported-browser .settings__menu {
        top: 85px;
    }

    .not-supported-browser-notification {
        height: 85px;
    }
}

@media (min-width: 768px) {
    body.not-supported-browser .header.header--push {
        height: 140px;
    }

    body.not-supported-browser .header,
    body.not-supported-browser .settings,
    body.not-supported-browser .settings__menu {
        top: 70px;
    }

    .not-supported-browser-notification {
        height: 70px;
    }
}

@media (min-width: 1000px) {
    body.not-supported-browser .header.header--push {
        height: calc(4rem + 70px);
    }

    body.not-supported-browser .header,
    body.not-supported-browser .settings,
    body.not-supported-browser .settings__menu {
        top: 4rem;
    }

    body.not-supported-browser .builder__header {
        margin-top: 4rem;
    }

    .not-supported-browser-notification {
        height: auto;
    }
}