.library.library--style-library .library__videos .video-link__actions button.button--color-blue-ribbon {
    padding: 0 5px;
    height: 27px;
    line-height: 26px;
    font-size: 14px;
}

.library.library--style-library .library__videos .video-link {
    overflow: visible;
}

.table--style-library .table__video {
    width: 750px;
}

.library__videos .video-link__actions li {
    margin-right: 2.8rem;
}
