.date-range-selector {
    line-height: normal;
    position: relative;
    z-index: 11;
    display: inline-block;
}

.date-range-selector .react-datepicker-wrapper {
    width: auto!important;
}

.date-range-selector .date-range-separator {
    display: inline-block;
    margin: 0 5px
}