.enroll-sequence-steps {
    margin: 40px 0;
}

.enroll-sequence-steps .reset-steps {
    text-align: right;
    margin-bottom: 20px;
}

.enroll-sequence-steps table td:last-child {
    text-align: center;
}

.enroll-sequence-steps .buttons a {
    color: #000
}

.enroll-sequence-steps td:not(.datepicker) {
    line-height: 50px!important;
}

.enroll-sequence-steps td img {
    max-width: 100px;
    max-height: 100px;
}

.enroll-sequence-steps img.thumbnail {
    max-width: 100%;
    max-height: 100px;
}

.enroll-sequence-steps .react-datepicker-wrapper .form__input {
    padding: 0;
    height: auto;
    font-size: 18px;
}

.table.enroll-sequence-steps .error, .builder--style-audience .error {
    margin: 20px 0;
    color: red;
}