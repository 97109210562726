.video-preview-container {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    z-index: 1;
    margin: 0 auto;
}

.video-preview-container .video-preview-title {
    font-size: 17px;
    padding: 15px;
    position: relative;
    z-index: 10;

    background-color: #141e2f;
    color: #fff;
    font-family: "Greycliff Font", sans-serif;
    font-weight: 600;
    line-height: 1.2;
}

.video-preview-container .video-react {
    position: absolute;
    top: 0;
}

@media (min-width: 375px) {
    .video-preview-container {
        height: 192px;
        width: 343px;
    }
}

@media (min-width: 768px) {
    .video-preview-container {
        height: 250px;
        width: 444px;
    }
}