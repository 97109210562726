.builder--style-message .builder__message-message textarea {
    width: 100%;
    border: none;
    background: none;
    height: 30vh;
}

.builder--style-message .builder__message-status.over-limit {
    color: red;
}


.builder--style-message .legend-button {
    padding-top: 20px;
}

.builder--style-message .legend-modal ul {
    margin-top: 20px;
    margin-bottom: 20px;
}