@media (min-width: 760px) {
    .builder__header .cnt {
        padding-right: 0;
    }
    .builder__header-steps {
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -moz-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-align: center;
        -webkit-box-align: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .builder__header-steps-item {
        width: 140px
    }

    .builder__header-steps-arrow {
        margin: 0 15px;
    }

    .builder__header-buttons {
        margin-left: auto;
    }

    .builder__header-buttons .builder__header-button--next {
        max-width: 60px;
    }
}

@media (min-width: 992px) {
    .builder__header-buttons {
        margin-left: unset;
    }

    .builder__header-buttons .builder__header-button--next {
        max-width: unset;
    }
}