@media (min-width: 768px) {
    .library__categories-item--size-double .library__categories-item-description,
    .library__categories-item--size-double .library__categories-item-title {
        width: 60%;
        max-height: 100px;
        overflow: hidden;
    }

    .library__categories-item--size-normal .library__categories-item-description,
    .library__categories-item--size-normal .library__categories-item-title {
        width: 100%;
        max-height: 100px;
        overflow: hidden;
    }

    .library__categories-item {
        position: relative;
        height: 260px;
    }

    .library__categories-item button.button--style-outline {
        position: absolute;
        bottom: 30px;
        left: 30px;
    }
}

.library__categories-item .library__categories-item-title.category {
    color: white;
    padding-bottom: 3px;
}

.library__categories-item .builder__type {
    text-align: center;
    margin-top: 50px;
}

.library__categories-item .builder__type .builder__type-description {
    color: #66748E;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
}

.library__categories-item .builder__type .builder__type-title {
    color: #0E254E;
    font-family: "Greycliff Font", sans-serif;
    font-size: 17px;
    font-weight: 700;
    margin: 20px 0 10px 0;
    text-align: center;
}

.library__categories-item .builder__type .builder__type-icon .icon {
    font-size: 44px;
    margin: 0 auto;
}