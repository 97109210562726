.recorder__close {
    z-index: 10;
}

.video-react {
    overflow: hidden;;
}

.video-react-control-bar {
    z-index: 20
}

.font-settings-toolbar {
    flex: 0 0 auto;
    display: inline-flex;
}

.font-settings-toolbar .input-container {
    margin: 0 10px;
}

.font-settings-toolbar .input-container label {
    color: white;
    margin-right: 10px;
}

.font-settings-toolbar .input-container input,
.font-settings-toolbar .input-container label {
    vertical-align: middle;
    display: inline-block;
}

.modal--type-save-script {
    z-index: 99999;
}

.recorder .react-hint__content {
    color: #000;
    background-color: #fff;
    display: flex;
    max-width: 440px;
    overflow: auto;
}

.recorder .react-hint__content .hint-frame {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #flex-basis: 220px;
}

.recorder .react-hint__content button {
    margin: 0 5px;
}

.recorder .react-hint__content p {
    text-align: center;
    margin-bottom: 10px;
}

.recorder .react-hint__content img {
    max-width: 80px;
    max-height: 80px;
}

.recorder .react-hint__content .item-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recorder .preview {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: block;
    margin: auto;
    max-width: 100%;
}

.recorder__script-holder {
    overflow: hidden;
}

.recorder__script-text {
    left: 0;
    right: -17px;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.6;
    text-align: center;
    white-space: pre-wrap;
    color: #efeff4;
    text-shadow: 0 2px 4px #000;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}

.recorder__toolbar-option--frames {
    margin-right: 20px;
}

.recorder__toolbar-option--color .react-hint--top:after {
    display: none;
}

.recorder__script-text {
    font-weight: 400;
}

.video-editor .full-width-time-control {
    margin-bottom: 30px;
}

.video-editor .full-width-time-control .video-react-progress-control {
    flex: none;
    width: 100%;
    position: absolute;
    top: 30px;
    background-color: rgba(43, 51, 63, 0.7);
}

.recorder__gallery.font-settings-toolbar {
    overflow: visible;
}

.recorder-start-stop-timer {
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    position: relative;
    text-align: center;
}

.video-editor .video-react .video-react-video {
    padding-right: 22%;
}

.recorder--style-edit-asset .video-poster-container {
    padding-right: 22%;
    object-fit: contain;
    box-sizing: content-box;
}

.recorder--style-edit-asset .video-measure {
    width: auto !important;
    margin-right: 22%;
}

.video-player-poster, .custom-video-frame {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.custom-video-frame {
    background-size: 100% 100%;
}

.video-player-poster {
    z-index: 12;
    background-size: cover;
}

.frame__bar {
    position: absolute;
    z-index: 2;
    left: 25px;
    right: 25px;
    bottom: 25px;
    height: 30px;
    padding: 8px 15px;
    background: #141e2f;
    white-space: nowrap;
    line-height: 16px;
    color: #fff;
    font-size: 12px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: flex;
    font-family: "Roboto", sans-serif;
    opacity: 0.7;
}

.video-react-big-play-button {
    z-index: 10;
}

.recorder__toolbar {
    z-index: 33;
}


/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    background: #fff url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

.slick-prev:before {
    content: '< ';
}

.slick-next {
    right: -25px;
}

.slick-next:before {
    content: '>';
}

/* Dots */
.slick-dots {
    display: none;
}

/* Custom */

.slick-slider {
    margin: 0 45px;
    padding: 30px;
}

.recorder--style-edit-asset .slick-slider .recorder__gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    position: relative;
}

.recorder--style-edit-asset .slick-slider .recorder__gallery-item .close{
    position: absolute;
    top: 0;
    right: -15px;
    cursor: pointer;
    color: white;
}

.recorder--style-edit-asset .slick-slider .recorder__gallery-item img {
    max-width: 100%;
    max-height: 100%;
}

.recorder--style-edit-asset .slick-list {
    height: 75px;
}

.small-screen-mobile-record-launcher {
    display: block;
    position: fixed;
    top: 22px;
    right: 22px;
    margin-left: -125px;
}

@media (min-width: 1200px) {
    .small-screen-mobile-record-launcher {
        display: none;
    }
}

.recorder-frame-buttons {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recorder-frame-buttons button {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    background-color: rgba(255,255,255, 0.5);
    font-size: 3rem;
    margin: 20px;
    z-index: 100;
}

@media (max-width: 600px) {
    .recorder-frame-buttons button {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        font-size: 1.5rem;
    }
}

.react-contexify {
    font-size: 16px;
}

/* Script generator styles */

.script-assistant.modal--type-recording-done .modal__window {
    max-width: 80%;
    position: relative;
    background-color: #343541;
}

.script-assistant.modal--type-recording-done .modal__window .modal__title {
    display: none;
}

.script-assistant .script-assistant-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top:  20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.script-assistant .script-assistant-container .script-assistant-log {
    flex-grow: 1;
    min-height: 200px;
    border: solid 1px #FFF;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow-y: auto;
    position: relative;
}

.script-assistant .script-assistant-container .script-assistant-log .record {
    padding: 10px;
    margin-right: 50px;
    display: flex;
    align-items: flex-start;
}

.script-assistant .script-assistant-container .script-assistant-log .script-assistant-logo {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 20px;
    top: 20px;
}

.script-assistant .script-assistant-container .script-assistant-log .record.instruction {
    background-color: #343541;
    color: #ececf1d9;
}

.script-assistant .script-assistant-container .script-assistant-log .record.answer {
    background-color: #444654;
    border: none;
    color: #ececf1d9;
    position: relative;
}

.script-assistant .script-assistant-container .script-assistant-log .record.answer svg {
    margin-right: 10px;
}

.script-assistant .script-assistant-container .script-assistant-log .record.answer .use-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -50px;
    width: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444654;
}

.script-assistant .script-assistant-textarea {
    display: flex;
    flex-direction: column;
    justify-content: end;
    max-height: 100px;
    min-height: 35px;
    overflow-y: auto;
    border: solid 1px #FFF;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    position: relative;
}

.script-assistant .script-assistant-textarea .send-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.script-assistant .modal__buttons {
    margin: 20px auto 0;
}

.chatgpt-textarea {
    background-color: #353741!important;
    padding: 10px!important;
}

.chatgpt-textarea::placeholder {
    text-align: center;
    padding-top: 10px;
}

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
}
.ripple:hover {
    background: #0B59F0 radial-gradient(circle, transparent 1%, #0B59F0 1%) center/15000%;
}
.ripple:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
}
