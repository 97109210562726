.schedule-date-time-pick {
    .date-time-text {
        font-size: 27px;
        font-weight: 300;

        span:first-child {
            margin-right: 20px;
        }
    }

    .react-datepicker-wrapper {
        display: inline-block;
        width: 180px;
        cursor: pointer;
        line-height: 26px;
        border-radius: 4px;
        border-bottom: 2px solid transparent;

        &:hover {
            border-bottom: 2px solid #12B2D9;
        }

        .react-datepicker__input-container span {
            margin-right: 0;
            display: block;
        }
    }

    .rc-time-picker {
        input {
            border: none;
            background: none;
            font-size: 27px;
            color: #000;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            width: 40%;
        }

        &:hover {
            input {
                border-bottom: 2px solid #12B2D9;
            }
        }
    }
    
    .picker-opened .rc-time-picker input {
        visibility: hidden;
    }

    &.rc-time-picker-panel {
        width: 225px;

        .rc-time-picker-panel-inner {
            margin-top: 45px;
            transform: translateX(1.1px);

            .rc-time-picker-panel-select {
                width: 75px;
                max-height: 300px;

                li {
                    height: initial;
                    padding: 13px;
                    font-size: 24px;
                }
            }

            .rc-time-picker-panel-select:nth-child(3) {
                text-transform: uppercase;
            }
        }

        .rc-time-picker-panel-input-wrap {
            border: none;
            background: none;
            position: absolute;
            top: -51px;
            left: -1px;
        }
        .rc-time-picker-panel-input {
            border: none;
            background: none;
            font-weight: 300;
            font-size: 27px;
        }
    }
 
}

.builder__schedule-form-schedule.schedule-date-time-pick .form__container {
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    border-bottom: 1px solid #eceff1;
}

.builder__schedule-form-schedule.schedule-date-time-pick .form__container > .react-datepicker-wrapper,
.builder__schedule-form-schedule.schedule-date-time-pick .form__container > span {
    display: block;
    line-height: 70px;
    height: 70px;
    border-bottom: none !important;
    border-radius: 0;
    font-size: 27px;
    flex: 1 1 auto;
    width: auto;
    white-space: nowrap;
}

.builder__schedule-form-schedule.schedule-date-time-pick .form__container > .react-datepicker-wrapper {
    padding-right: 40px;
}

.builder__schedule-form-schedule.schedule-date-time-pick .form__container > span * {
    padding: 0;
    margin: 0;
    border: none !important;
    display: block;
    line-height: 70px;
    height: unset;
    width: 100%;
    font-size: 27px;
    border-radius: 0;
}

.schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-inner {
    margin-top: 70px !important;
    -webkit-transform: none !important;
    transform: none !important;
}

.schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-input-wrap {
    top: -68px !important;
    height: 70px !important;
    line-height: 70px !important;
    left: -1px !important;
}

.schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-input {
    line-height: 70px !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 27px !important;
}

.schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-input-wrap {
    padding: 0 !important;
    margin: 0 !important;
}

@media (max-width: 768px) {
    .builder__schedule-form-schedule.schedule-date-time-pick .form__container > .react-datepicker-wrapper,
    .builder__schedule-form-schedule.schedule-date-time-pick .form__container > span {
        line-height: 50px;
        height: 50px;
        font-size: 20px;
    }

    .builder__schedule-form-schedule.schedule-date-time-pick .form__container > .react-datepicker-wrapper {
        padding-right: 25px;
    }

    .builder__schedule-form-schedule.schedule-date-time-pick .form__container > span * {
        line-height: 50px;
        font-size: 20px;
    }

    .schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-inner {
        margin-top: 50px !important;
    }

    .schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-input-wrap {
        top: -48px !important;
        height: 50px !important;
        line-height: 50px !important;
    }

    .schedule-date-time-pick.rc-time-picker-panel .rc-time-picker-panel-input {
        line-height: 50px !important;
        font-size: 20px !important;
    }
}