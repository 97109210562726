.embed-video-button-wrapper {
    text-align: center;
    margin-top: 20px;
    line-height: 20px;
}

@media (max-width: 550px) {
    .embed-video-button-wrapper button {
        margin-bottom: 20px;
    }
}

