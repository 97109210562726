.sequence-steps .form__input {
    border-bottom: solid 1px gray;
}

.table.sequence-steps {
    margin: 40px 0;
}

.table.sequence-steps .add-step {
    text-align: right;
    margin-bottom: 20px;
}

.sequence-steps table th,
.sequence-steps table td,
.sequence-steps table td input{
    text-align: center;
}

.sequence-steps table td .css-dvua67-singleValue {
    left: 50%;
    transform: translate(-50%, -50%);
}

.sequence-steps table.table__table tbody.table__body td {
    overflow: visible;
}

.sequence-steps table.table__table tbody.table__body td.buttons {
    line-height: 40px;
}

.sequence-steps table.table__table tbody.table__body td.buttons a {
    color: #000;
}

.sequence-steps .form__input {
    line-height: 37px;
}

.table.sequence-steps .error {
    margin: 20px 0;
    color: red;
}

.table.sequence-steps td img {
    max-width: 100%;
    max-height: 100px;
}