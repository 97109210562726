.landing-page-logo {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.display-linebreak {
    white-space: pre-line;
    display: block;
    width: 100%;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

@media (min-width: 992px) {
    .message__more-item {
        min-width: 25%;
    }
}

.dark-mode .message__more-title {
    color: #fff
}

.dark-mode .message__link {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.075);
}

.dark-mode .message__link-title {
    color: #f1dab1;
}

.dark-mode .message__link-text {
    color: #998b71
}
