.upload-button-component input  {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
    width: 100%;
}

.upload-button-component {
    overflow: hidden;
    position: relative;

    cursor: pointer;
}
