.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    z-index: 99;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media (max-width: 550px) {
    .backdrop {
        padding: 0;
    }
    .modalWindow {
        max-width: 100%!important;
        height: 100%;
    }
}

.backdrop::-webkit-scrollbar {
   display: none;
}

.modalWindow {
    background-color: #fff;
    border-radius: 5px;
    #min-height: 300px;
    margin: 0 auto;
    padding: 30px
}