@media (min-width: 375px) {
    .toolbar__navigation-pull-right {
        display: none;
    }
}

@media (min-width: 768px) {
    .cnt.analytic {
        width: 100%;
        max-width: unset;
    }
}

@media (min-width: 1200px) {

    .toolbar__navigation-pull-right {
        display: block;
    }
}

.analytic {
    padding: 0 20px;
}

.analytic .library__split-left {
    padding-top: 0;
}

.analytic .toolbar--style-reports-time {
    background-color: transparent;
    margin-top: 35px;
}

.analytic .toolbar--style-reports-type {
    background-color: transparent;
    margin: 0;
    padding-top: 0;
}

.analytic .toolbar--style-reports-time .toolbar__navigation ul li {
    position: relative;
    margin-right: 5rem;
}

.toolbar__navigation.analytic-type-selector ul li {
    font-size: 20px;
    font-weight: bold;
}

.analytic .care__item-icon {
    position: absolute;
    left: 40px;
    top: 30px;
}

.analytic .toolbar__stats {
    cursor: pointer;
}

.analytic .general-stats .message-stats-title {
    display: none;
}

.analytic .table--style-people table.table__table tbody.table__body tr td {
    line-height: unset;
}

.analytic .library__preview-title span.title-part {
    display: inline;
    vertical-align: middle;
    margin: 0 5px;
}

.analytic .library__preview-title svg {
    top: 3px;
    position: relative;
    display: inline;
}

.analytic .table-recipients {
    min-width: 600px;
}

.analytic .table-recipients td, .analytic .table-recipients th {
    width: 12%!important;
    text-align: center;
}

.analytic .table-recipients td:first-child, .analytic .table-recipients th:first-child {
    width: 25%!important;
}

.analytic .table-recipients td:last-child, .analytic .table-recipients th:last-child {
    width: 15%!important;
}

@media (min-width: 544px) {
    .table--style-reports-shares .table__wrapper {
        overflow-x: scroll;
    }
}

@media (min-width: 1200px) {
    .table--style-reports-shares .table__wrapper {
        overflow-x: visible
    }
}

.toolbar--style-reports-stats .toolbar__stats-data {
    padding: 0 10px;
}

.toolbar--style-reports-stats .toolbar__stats-count {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
