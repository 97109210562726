body.dark-mode {
    background-color: #202020;
}

body.dark-mode .message__title, body.dark-mode .message__intro {
    color: #bdc3cf;
}

body.dark-mode .message__social-icon {
    background-color: #D0D5DD;
    color: 	#4C4C4C;
}

body.dark-mode .message__social-icon:hover {
    background-color: #12b2d9;
}

.dark-mode-control {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

body.dark-mode .dark-mode-control {
    color: #bdc3cf;
}