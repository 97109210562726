.header .header__content .header__logo.navigation-hidden {
    margin-top: 17px;
}
.header .header__navigation .disabled {
    opacity: 0.5;
}

.popover__menu-item-title {
    cursor: pointer;
    width: 100%;
    display: block;
}