.import-customers-form {
    width: 50%;

    .contacts-certified, .perform-upload {
        margin-top: 30px;
    }


}

.import-instruction {
    margin-bottom: 40px;
    li {
        list-style: decimal;
        margin: 5px 0;
        color: #66748E
    }
}