.form__container__error input,
.form__container__error textarea,
.form__container.form__container__error input,
.form__container.form__container__error textarea
{
    background-color: pink;
    color: black;
}

.form__container__error input::placeholder,
.form__container__error textarea::placeholder,
.form__container.form__container__error input::placeholder,
.form__container.form__container__error textarea::placeholder
{
    color: black;
}

.form__container__error .form__note,
.form__container.form__container__error .form__note
{
    color: red;
}