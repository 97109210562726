.builder.builder--style-select-type {
    .builder__content {
        align-items: initial;   
    }

    .builder__type--small {
        flex: 0 1 45%;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.075);
        cursor: pointer;
    }
}